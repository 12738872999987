#device-menu {
  &-options {
    max-width: 300px;
    width: 60vw;

    button {
      display: block;
      width: 100%;
      padding: .7em;
    }
  }

  &-built {
    text-align: center;
    color: #bbb;
    font-size: 70%;
  }
  
}