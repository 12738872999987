html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Default styles
ul, li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  border: 0;
  padding: 0;
  background: none;
  outline: none;
}

h1, h2 {
  margin: 0;
  padding: 0;
}

hr {
  border: none;
  border-bottom: 1px solid #ccc;
}

// Forms
form .form-control {
  padding-bottom: .5em;
}

// Root should fill space
#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}