#device-list {

  $iconWidth: 4em;
  $controlWidth: 2em;
  $borderColor: rgba(0, 0, 0, .05);
  $spaceBetween: .8em;

  padding: $spaceBetween;

  > .item {
    grid-template-columns: $iconWidth 1fr auto auto;
    grid-template-rows: 1fr 1fr;
    grid-gap: .25em;

    user-select: none;
    display: grid;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.06);
    border: 1px solid $borderColor;
    border-radius: 3px;
    padding: 1em .75em;
    margin-bottom: $spaceBetween;
  }

  .status {
    grid-column: 1;
    grid-row-end: span 2;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: $iconWidth;
    
    svg {
      flex: 1;
      width: 100%;
      height: 100%;
    }
  }

  .name {
    grid-column: 2;
    grid-row: 1;

    display: flex;
    font-size: $iconWidth / 3;
    text-align: left;
    flex-direction: column-reverse;
  }

  .detail {
    grid-column: 2;
    grid-row: 2;

    display: flex;
    text-align: left;
    flex-direction: column;
    font-size: 80%;

    .last-update {
      color: rgba(0,0,0,.5);
    }

    .update-ready {
      $color: #6fa8dc;
      color: $color;

      .update-circle {
        $dim: .6em;
        vertical-align: middle;
        width: $dim;
        height: $dim;
        background-color: $color;
        border-radius: $dim;
        display: inline-block;
        margin-right: .25em;

        animation-duration: .7s;
        animation-name: fadeCircle;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }
  }

  .temp {
    display: flex;
    grid-column: 3;
    grid-row: span 2;
    align-items: center;

    font-size: $iconWidth;
    color: rgba(0,0,0,.2);

    sup {
      font-size: 40%;
      padding-bottom: 1em;
    }
  }

  .control {
    grid-column: 4;
    grid-row: span 2;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    .up, .down {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: $controlWidth;
      padding: 0;
      
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .up {
      justify-content: flex-start;
    }
    .down {
      justify-content: flex-end;
    }

    .diff {
      color: red;
    }
  }
}

@keyframes fadeCircle {
  from {
    opacity: 1;
  }

  to {
    opacity: .5;
  }
}