@import '../Common.scss';

$themeColor:  #ffff00;

// Styles
html {
  height: 100%;
  background-color: $themeColor;
}

body {
  background-color: #fff;
  min-height: 100vh;
}

#App {
  &-header {

    h1 {
      font-size: 42px;
    }

    box-sizing: border-box;
    background-color: $themeColor;
    height: 7em;
    padding: .8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  &-main {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

/// Pinned header
body.pinned {
  $height: 71px;
  $scrollPast: 42px;
  padding-top: $height + $scrollPast;

  #App-header {
    height: $height;
    min-height: unset;
    z-index: 2;
    left: 0; right: 0;
    top: 0;
    position: fixed;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.05);
  }
}

// Desktop-specific
@media (min-width: $desktopMin) {

  #App-main {
    position: relative;
    width: 100%;
    min-width: $desktopMin;
    max-width: 60vw;
    margin: 0 auto;
  }
  
}