#heattarget {

  h3 {
    margin-top: 0;
  }

  // Dont allow scroll in content
  &-content {
    overflow: hidden;
  }

  // Temperature slider
  &-slider {
    display: flex;
    align-items: center;
    padding-top: .5em;
    padding-bottom: 1em;

    &-touch {
      padding: 1em 0;
      flex: 1;
    }

    &-num {
      padding-left: 2em;
    }
  }

  // Duration
  &-list {

    > label {
      display: block;
      padding: .5em;

      input {
        margin-right: 1em;
      }
    }
  }
}