#device-add-btn {
  $float: 0;
  $dim: 3em;

  position: fixed;
  bottom: $float;
  right: $float;
  z-index: 2;
  
  svg {
    width: $dim;
    height: $dim;

    path {
      transition: fill .4s;
    }
  }
}

#device-add-inline-btn {
  padding: 0 0.8em;

  button {
    width: 100%;
  }
}