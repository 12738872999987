/// Splash screen
#splash {
  color: #AAA;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 13.5vh;
  
  &-logo {
    box-sizing: border-box;
    width: 144px;
    height: 144px;
    
    margin: 0 auto;
    background: transparent url(/icons/black_192.png) no-repeat center center;
    background-size: contain;

    &.animate {
      animation-duration: 0.5s;
      animation-name: dimLogo;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }

  &-info {
    padding: .5em;
  }

  @keyframes dimLogo {
    from {
      opacity: 1;
    }
  
    to {
      opacity: .85;
    }
  }
}