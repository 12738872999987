.toast-message {
  position: fixed;
  width: 80vw;
  max-width: 600px;
  left: 10vw;
  right: 10vw;
  margin: 0 auto;
  bottom: 3em;

  border-radius: 100px;
  background-color: rgba(0,0,0,.8);
  color: #F0F0F0;

  z-index: 4;
  display: block;
  padding: .5em;
  text-align: center;

  &.fadeout {
    animation: fade .2s ease-in-out reverse, fade 1s ease-in-out 5s;
    animation-fill-mode: forwards;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}