#auth {
  padding-top: 2em;

  &-button {
    width: (382px / 2);
    height: (92px / 2);

    background: transparent url(./btn_google_signin_light_normal_web@2x.png);
    background-size: contain;
    background-repeat: no-repeat;

    &:active {
      background-image: url(./btn_google_signin_light_pressed_web@2x.png);
    }
  }
}