// Transitions
.page {
  flex: 1;
  display: flex;
  flex-direction: column;

  &-exit {
    background: #fff;
    pointer-events: none;

    position: absolute;
    z-index: 3;
    opacity: 1;
    left: 0;
    right: 0;
    height: 100%;

    &-active {
      opacity: 0;
      transition: opacity 100ms ease-out;
    }
  }
}